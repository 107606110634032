<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="500px"
    title="文件夹访问权限">
    <el-form
      class="t-form"
      label-width="132px"
      label-position="left"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="选中组员不可见">
        <el-checkbox-group
          v-model="params.userId"
          v-if="userList && userList.length">
          <el-checkbox
            v-for="v in userList"
            :key="v.userId"
            :label="v.userId">{{ v.userName }}</el-checkbox>
        </el-checkbox-group>
        <span v-else class="fs16">暂无组员</span>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
     <t-btn
     	w="78px"
     	h="36px"
     	fs="14px"
     	type="info"
     	@click="visible = false" 
     	class="bold">取消</t-btn>
     <t-btn
       @click="commit"
       w="78px"
       h="36px"
       fs="14px"
       class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      options: null,
      params: {
        userId: [],
      },
      userList: []
    }
  },
  methods: {
    // 确认
    commit() {
      this.$emit('commit', { ...this.params, ...this.options })
    },
    // 显示
    show(options = null) {
      this.options = options
      this.visible = true
      let api = options.isFile ? 'getUsersDenyByFile' : 'getUsersDenyByFloder'
      this.$api.cases[api]({ id: options.id }).then(res => {
        this.userList = res.items
        this.params.userId = res.items.reduce(function(arr, v) {
          if(v.checked) {
            return arr.concat(v.userId)
          }
          return arr
        }, [])
      })
    },
    // 关闭重置
    closed() {
      this.options = null
      this.params = {
        userId: [],
      }
      this.userList = []
    }
  }
}
</script>
